import React from 'react'

export default function Home() {
    return (
        <div>
            <div style={{ margin: '50px' }} >
                <h1>My Project list</h1>
            </div>
            <div style={{ margin: '50px', display: 'flex' }}>
                <a href='https://pandd.vercel.app/' target='_blank'>P and D</a>
            </div>
        </div>
    )
}
